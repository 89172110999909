.projects {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projects .projectTitle {
  text-align: center;
  color: #3e497a;
  font-size: 60px;
  font-weight: bolder;
}

.projectList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

/* MENU ITEM STYLING */

.projectItem {
  border-radius: 15px;
  width: 300px;
  height: 300px;
  margin: 40px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.projectItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.projectItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItem h1 {
  font-size: 25px;
}

@media only screen and (max-width: 1300px) {
  .projectList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectList {
    width: 100%;
  }
  .projectItem {
    width: 300px;
    height: 300px;
  }
}